<template>
  <div class="justify-center full-height" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row justify-between items-end">
        <div class="text-h4">Banners</div>
        <MediaSelect class="col-3" v-bind:selection.sync="selection" label="Showing Banners for:" v-if="$store.getters['auth/homePageFilter']" />
      </div>

      <div class="row justify-between q-mt-lg">
        <q-btn @click="setAction('Create')" color="primary">Add New Banner</q-btn>
      </div>

      <div v-if="filteredBanners.length" class="q-my-xl">
        <q-list>
          <draggable handle=".drag-handle-item" v-model="filteredBanners">
            <q-item v-for="banner in filteredBanners" :key="banner.uid" class="q-my-md">
              <q-item-section avatar class="col-1 drag-handle-item cursor-pointer">
                <q-icon name="drag_indicator"/>
              </q-item-section>
              <q-item-section class="col-9 drag-handle-item cursor-pointer">
                <q-img :src="banner.path" style="max-width: 30vw; height:auto;" />
              </q-item-section>
              <q-item-section side class="primaryText-text">
                <q-btn flat round icon="edit" @click="setAction('Edit', banner)"/>
              </q-item-section>
              <q-item-section side class="primaryText-text q-mx-xl">
                <q-btn flat round icon="delete" @click="deleteBanner(banner)"/>
              </q-item-section>
            </q-item>
          </draggable>
        </q-list>
      </div>
      <div v-else class="q-my-xl col-xs-12">
        <div align="center">There are currently no banners for this Location or User Group.</div>
      </div>

      <q-dialog v-model="showBannerModal" full-width full-height>
        <q-card class="bg-lmsBackground primaryText-text">
          <q-form @submit="mode === 'Create' ? createBanner() : updateBanner()" @reset="setAction(null)" autocomplete="off">
            <q-card-section class="row justify-between">
              <div class="text-h5">{{ mode }} Banner</div>
              <q-btn dense flat icon="close" style="z-index: 2" @click="setAction(null)">
                <q-tooltip>Close</q-tooltip>
              </q-btn>
            </q-card-section>
            <q-file ref="imageSelect" class="col-8 hidden" filled v-model="bannerImage" accept="image/*" label="Category Image (Actual Size: 250x140)" />
            <q-card-section class="row justify-center">
              <div class="col-12 center" id="cropperContainer" style="max-height: 400px">
                <img ref="cropperImage" id="cropper" src="" />
              </div>
              <div v-if="showCropButton" class="row full-width justify-center q-mt-lg">
                <q-btn color="primary" label="Crop" @click="cropImageToBlob" />
              </div>
            </q-card-section>
            <div class="row">
              <div class="col-4 col-xl-3 col-lg-2 col-md-2 col-sm-2">
                <div class="text-h6 q-mt-sm text-right q-mr-lg">Banner Image</div>
                <div class="text-caption q-mr-md text-right">Recommended Size (1130x400)</div>
              </div>
              <div v-if="imagePreview" class="col-8 col-xl-9 col-lg-10 col-md-12 col-sm-12 row ">
                <img :src="imagePreview" style="max-height: 400px; max-width: 1130px; " />
              </div>
              <div v-else class="col-8 col-xl-9 col-lg-10 col-md-12 col-sm-12">
                <q-btn label="Add Image" color="primary" @click="$refs.imageSelect.$el.click()" />
              </div>
            </div>
            <div v-if="imagePreview" class="row q-mt-md">
              <div class="col-4 col-xl-3 col-lg-2 col-md-2 col-sm-2"></div>
              <div class="col-8 col-xl-9 col-lg-10 col-md-12 col-sm-12 row">
                <q-btn label="Change Image" color="primary" @click="$refs.imageSelect.$el.click()" />
              </div>
            </div>
            <div v-if="$store.getters['auth/homePageFilter']" class="row q-my-xl">
              <div class="col-4 col-xl-3 col-lg-2 col-md-2 col-sm-2">
                <div class="text-h6 q-mt-sm text-right q-mr-lg">Assign Banner</div>
              </div>
              <div class="col-8 col-xl-9 col-lg-10 col-md-12 col-sm-12 row">
                <MediaSelect class="col-6" v-bind:selection.sync="banner.assignedUid" label="Assign To" />
              </div>
            </div>
            <div class="row q-my-xl">
              <div class="col-4 col-xl-3 col-lg-2 col-md-2 col-sm-2"></div>
              <div flat class="col-8 col-xl-9 col-lg-10 col-md-12 col-sm-12 bg-lmsBackground">
                <div class="row q-my-xl">
                  <q-btn class="col-3" type="submit" color="primary" :disable="showCropButton || !imagePreview">Submit</q-btn>
                  <q-btn flat type="reset">Cancel</q-btn>
                </div>
              </div>
            </div>
          </q-form>
        </q-card>
      </q-dialog>

    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import Cropper from 'cropperjs'
import MediaSelect from 'components/MediaSelect'

export default {
  components: { draggable, MediaSelect },
  data () {
    return {
      mode: null,
      showBannerModal: false,
      bannerImage: null,
      croppedBannerImage: null,
      imagePreview: null,
      showCropButton: false,
      banner: {
        file: null,
        assignedUid: null,
        display_order: '0'
      },
      selection: null
    }
  },
  computed: {
    banners: {
      get () {
        return this.$store.state.banners.data
      },
      set (data) {
        _.forEach(data, (banner, i) => {
          if (!_.isEmpty(this.banners[i])) {
            banner.display_order = i + 1
          }
        })
        this.$store.commit('banners/update', data)
        this.$store.dispatch('banners/order', data)
      }
    },
    filteredBanners: {
      get () {
        return this.$store.getters['banners/getByAssignedUid'](this.selection)
      },
      set (data) {
        _.forEach(data, (banner, i) => {
          if (!_.isEmpty(this.banners[i])) {
            banner.display_order = i + 1
          }
        })
        this.$store.commit('banners/update', data)
        this.$store.dispatch('banners/order', data)
      }
    }
  },
  watch: {
    bannerImage (newVal) {
      if (newVal) {
        this.createCropper(newVal)
      }
    }
  },
  methods: {
    setAction (mode, banner = null) {
      this.mode = mode
      this.banner = banner ? { ...banner } : this.banner
      this.showBannerModal = true

      if (mode === 'Edit') {
        this.imagePreview = banner.path
        this.banner.assignedUid = banner.assigned_uid
      }

      if (mode === null) {
        this.cancelForm()
      }
    },
    cancelForm () {
      let selection = this.selection
      Object.assign(this.$data, this.$options.data.apply(this))
      this.selection = selection
      this.banner.assignedUid = selection
    },
    createCropper (tFile) {
      if (tFile.path) {
        document.querySelector('#cropper').src = tFile.path
      } else {
        document.querySelector('#cropper').src = (window.URL || window.webkitURL).createObjectURL(tFile)
      }
      document.querySelector('#cropperContainer').style.height = (window.outerHeight * 0.4) + 'px'
      this.showCropButton = true
      if (!this.$refs.cropperImage) {
        return
      }
      if (this.cropper) {
        this.cropper.destroy()
        this.cropper = false
      }
      let cropper = new Cropper(this.$refs.cropperImage, {
        aspectRatio: 17 / 6,
        viewMode: 2,
        responsive: true,
        autoCropArea: 1,
        dragMode: 'move'
      })
      this.cropper = cropper
    },
    cropImageToBlob () {
      let blobbanner = this.cropper.getCroppedCanvas()
      var uA = window.navigator.userAgent
      var isIE = /msie\s|trident\/|edge\//i.test(uA) && !!(document.uniqueID || document.documentMode || window.ActiveXObject || window.MSInputMethodContext)
      if (isIE) {
        this.croppedBannerImage = blobbanner.msToBlob()
        this.showImagePreview()
        this.showCropButton = false
      } else {
        blobbanner.toBlob((blobbanner) => {
          this.croppedBannerImage = blobbanner
          this.showImagePreview()
          this.showCropButton = false
        }, 'image/jpeg', 1)
      }
    },
    showImagePreview () {
      let reader = new FileReader()
      reader.readAsDataURL(this.croppedBannerImage)
      reader.onloadend = () => {
        this.imagePreview = reader.result
        this.imageIsCropped = true
        document.getElementById('cropper').src = ''
        document.getElementById('cropperContainer').style.height = '0px'
        this.cropper.destroy()
      }
    },
    async createBanner () {
      this.$loading(true)
      this.banner.file = this.croppedBannerImage

      try {
        await this.$store.dispatch('banners/add', this.banner)
        this.setAction(null)
        this.$loading(false)
        this.$successMsg('Banner Added')
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    },
    async updateBanner () {
      this.$loading(true)
      if (this.croppedBannerImage) {
        this.banner.file = this.croppedBannerImage
      }

      try {
        await this.$store.dispatch('banners/update', this.banner)
        this.setAction(null)
        this.$loading(false)
        this.$successMsg('Banner Updated')
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    },
    async deleteBanner (banner) {
      this.$loading(true)

      try {
        await this.$store.dispatch('banners/remove', banner.uid)
        this.$loading(false)
        this.$successMsg('Banner Deleted')
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    }
  },
  created () {
    this.$store.dispatch('banners/fetch')
    this.selection = this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']
    this.banner.assignedUid = this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']

    let cropperCSS = document.createElement('link')
    cropperCSS.setAttribute('href', 'https://cdn.jsdelivr.net/npm/cropperjs/dist/cropper.css')
    cropperCSS.setAttribute('rel', 'stylesheet')
    document.head.appendChild(cropperCSS)
  }
}
</script>
